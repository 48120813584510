import React from "react";

function Store() {
  return (
    <div>
      <section
        class="hero-banner-sect"
        style={{ minHeight: "20vh" }}
        id="banner-sect"
      >
        <div class="hero-banner-sect-wrapper">
          <div class="container">
            <div
              className="image-container"
              style={{ zIndex: "-10", filter: "brightness(0.5)" }}
            >
              <img className="store-image" src="assets/img/store.avif" alt="" />
            </div>

            <div class="row" style={{ color: "black" }}>
              <div class="col banner-head-col">
                <div class="banner-head-wrapper my-5 pt-5">
                  <h2 class="banner-head-text pb-5">
                    <span>Breakthrough Store</span>
                    <br />
                    <p class="lead fw-normal">
                      Check Out All The Great BREAKTHROUGH MERCH!
                    </p>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main className="mt-5 container">
        <div className="row row-auto">
          <div className="col-lg-4 col-md-4 col-sm-6 sol-6">
            <div class="text-bg-dark me-md-3 pb-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
              <div class="my-3 py-3">
                <h2 class="display-5">$22.88</h2>
                <p class="lead">I Need Some Change Classic T-Shirt</p>
              </div>
              <div class="bg-dark shadow-sm mx-auto">
                <a
                  target="_blank"
                  href="https://www.redbubble.com/i/t-shirt/I-Need-Some-Change-by-BREPMGear/96840349.IJ6L0?utm_campaign=external-portfolio&utm_medium=embedded&utm_source=BREPMGear"
                >
                  <img
                    className="product-image"
                    src="assets/img/Product-1.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 sol-6">
            <div class="bg-light me-md-3 pb-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
              <div class="my-3 p-3 ">
                <h2 class="display-5">$22.88</h2>
                <p class="lead">
                  Breakthrough Real Estate & Property Management Logo Classic
                  T-Shirt
                </p>
              </div>
              <a
                target="_blank"
                href="https://www.redbubble.com/i/t-shirt/Breakthrough-Real-Estate-and-Property-Management-Logo-by-BREPMGear/74101758.E4JM1?utm_campaign=external-portfolio&utm_medium=embedded&utm_source=BREPMGear"
              >
                <img
                  className="product-image"
                  src="assets/img/Product-2.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 sol-6">
            <div class="text-bg-dark me-md-3 pb-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
              <div class="my-3 p-3 ">
                <h2 class="display-5">$22.88</h2>
                <p class="lead">Breakthrough Handyman Gear Classic T-Shirt</p>
              </div>
              <a
                target="_blank"
                href="https://www.redbubble.com/i/t-shirt/Breakthrough-Handyman-Gear-by-BREPMGear/74106658.EIU9L?utm_campaign=external-portfolio&utm_medium=embedded&utm_source=BREPMGear"
              >
                <img
                  className="product-image"
                  src="assets/img/Product-3.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 sol-6">
            <div class="bg-light me-md-3 pb-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
              <div class="my-3 py-3">
                <h2 class="display-5">$22.88</h2>
                <p class="lead">I'm Done... Classic T-Shirt</p>
              </div>
              <div class="shadow-sm mx-auto">
                <a
                  target="_blank"
                  href="https://www.redbubble.com/i/t-shirt/I-m-Done-by-BREPMGear/96840938.22W0K?utm_campaign=external-portfolio&utm_medium=embedded&utm_source=BREPMGear"
                >
                  <img
                    className="product-image"
                    src="assets/img/Product-4.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 sol-6">
            <div class="text-bg-dark me-md-3 pb-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
              <div class="my-3 py-3">
                <h2 class="display-5">$22.88</h2>
                <p class="lead">
                  I don't fail... I BREAKTHROUGH Classic T-Shirt.
                </p>
              </div>
              <div class="bg-dark shadow-sm mx-auto">
                <a
                  href="https://www.redbubble.com/i/t-shirt/I-don-t-fail-I-BREAKTHROUGH-by-BREPMGear/74121403.WFLAH?utm_campaign=external-portfolio&utm_medium=embedded&utm_source=BREPMGear"
                  target="_blank"
                >
                  <img
                    className="product-image"
                    src="assets/img/Product-5.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 sol-6">
            <div class="bg-light me-md-3 pb-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
              <div class="my-3 p-3 ">
                <h2 class="display-5">$20.76</h2>
                <p class="lead">
                  Breakthrough Real Estate & Property Management Logo Water
                  Bottle
                </p>
              </div>
              <a
                href="https://www.redbubble.com/i/water-bottle/Breakthrough-Real-Estate-and-Property-Management-Logo-by-BREPMGear/74101758.Y6CDO"
                target="_blank"
              >
                <img
                  className="product-image"
                  src="assets/img/Product-6.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <section>
          <div class="title-single-box mt-5">
            <h1 class="title-single border-bottom border-success pb-5">
              Click Below to findout More Products
            </h1>
          </div>
          <div class="more-product-list mx-0 w-auto">
            <li>
              <a href="https://www.redbubble.com/shop/ap/74101758">
                Breakthrough Real Estate and Property Management SWAG
              </a>
            </li>
            <li>
              <a href="https://www.redbubble.com/shop/ap/74100279">
                Plant a Breakthrough Fashion{" "}
              </a>
            </li>
            <li>
              <a href="https://www.redbubble.com/shop/ap/74106658">
                Breakthrough Handyman Gear
              </a>
            </li>
            <li>
              <a href="https://www.redbubble.com/people/brepmgear/works/74121403-i-dont-fail-i-breakthrough?asc=u">
                I don't fail... I BREAKTHROUGH
              </a>
            </li>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Store;
