import React, { useState,useEffect } from "react";
import { getData } from "../../../Services/Service";

import sampleData from "./SampleData";

function TrenchLife() {
  const [trenchData,setTrenchData]=useState([]);

  useEffect(() => {
    getData().then((response) => {
      setTrenchData(response.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  const [currentPlaying, setCurrentPlaying] = useState(sampleData[0]);
  const handleClick = (event) => {
    console.log(event.currentTarget.id);
    setCurrentPlaying(sampleData[event.currentTarget.id - 1]);
    scrollToTop();
  };
  return (
    <div className="container">
      <section className="my-5 py-3"></section>
      <div className="row row-auto">
        <div className="col-lg-8 trench-video-container">
          <div>
            <iframe
              width="100%"
              height="400"
              src={currentPlaying.link}
              title="Breakthrough Real Estate Daily Tidbits Episode 2: Find Your Why Pt. 1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p className="lead"><span>Episode {currentPlaying.id} :</span>{currentPlaying.title}</p>
            <p>{currentPlaying.description}</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="text-center">
            <h3 className="mb-3 display-6">Choose Episode</h3>
          </div>
          <div className="scrollable-container">
          {sampleData.map((data,index) => (
            <div key={index}  style={{ cursor: "pointer" }}>
              <p className="trench-video-heading" id={index+1} onClick={handleClick}>
                <span><b className="lead"> Episode {index+1} :</b></span>{data.title}
              </p>
              <br />
            </div>
          ))}
          </div>
         
        </div>
      </div>
      
    </div>
  );
}

export default TrenchLife;
