import React from 'react'
import { Link,  } from "react-router-dom";
export default function Thankyou() {
  return (
    <div>
        <section className="container">
        <div className="container mt-5 pt-5 ">
          <div className="row mt-5">
            <div className="col-lg-12 d-flex flex-column text-center justify-content-center ">
              <h1 className="main-heading mt-5">
                Thanks for connecting with us
              </h1>
              <p className=" text-center greeting">
                Have a nice day and We will try to reach you as soon as possible
              </p>
              <hr className="my-5" />
            </div>
           
            <p className="text-center">
              Having trouble? <Link to="/contact">Contact us</Link>
            </p>
            <p class="lead text-center">
            <Link to="/"><button type="submit" class="btn btn-success">
                  GO TO HOMEPAGE
                </button></Link> 
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}
