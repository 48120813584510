import React from "react";
import { Link } from "react-router-dom";

const date = new Date().getFullYear();
function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <section class="section-footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <div class="widget-a">
                <div class="w-header-a mb-3">
                  <Link
                    onClick={scrollToTop}
                    class="navbar-brand text-brand"
                    to="/"
                  >
                    <img
                      className="navbar-logo"
                      src="images/breakthroughLogo.png"
                      alt=""
                    />
                  </Link>
                  
                </div>
                <div class="w-body-a my-5"></div>
                <div class="w-footer-a">
                  <ul class="list-unstyled">
                    <li class="color-a">
                      <span class="color-text-a text-success">Phone </span>
                      <a href="tel:6234714451">623-471-4451</a>
                    </li>
                    <li class="color-a">
                      <span class="color-text-a text-success">Email </span>
                      <a href="mailto:info@brepm.com">info@brepm.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 section-md-t3">
              <div class="widget-a">
                <div class="w-header-a">
                  <h3 class="w-title-a text-brand">Legal</h3>
                </div>
                <div class="w-body-a">
                  <div class="w-body-a">
                    <ul class="list-unstyled">
                      {/* <li class="item-list-a">
                        
                        <a href="#">Site Map</a>
                      </li> */}
                      <li class="item-list-a">
                        <Link onClick={scrollToTop} to="/privacy-policy">
                          Privacy Policy
                        </Link>
                      </li>
                      <li class="item-list-a">
                        <Link onClick={scrollToTop} to="/terms">
                          Terms
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 section-md-t3">
              <div class="widget-a">
                <div class="w-header-a">
                  <h3 class="w-title-a text-brand">Our Company</h3>
                </div>
                <div class="w-body-a">
                  <ul class="list-unstyled">
                    <li class="list-inline-item">
                      <Link onClick={scrollToTop} to="/">
                        Home
                      </Link>
                    </li>
                    <li class="item-list-a">
                      <Link onClick={scrollToTop} to="/about">
                        About
                      </Link>
                    </li>
                    <li class="item-list-a">
                      <Link onClick={scrollToTop} to="/contact">
                        Contact
                      </Link>
                    </li>
                    {/* <li class="item-list-a">
                      <Link onClick={scrollToTop} to="/blog">
                        Blog
                      </Link>
                    </li> */}
                    <li>
                      <Link onClick={scrollToTop} to="/trench-life">
                        Trench Life
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollToTop} to="/store">
                        Store
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p className="text-center my-5 copy-text">
                Copyright ©{date} Breakthrough
                <br /> All rights reserved. Content may not be copied without
                written permission.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
