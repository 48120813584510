import React from "react";
import Form from "../Extra/Form";

function Home() {
  return (
    <div>
      <section>
        <section class="hero-banner-sect" id="banner-sect">
          <div class="hero-banner-sect-wrapper">
            <div class="container">
              <div className="video-container" style={{ zIndex: "-10" }}>
                <video autoPlay muted loop id="myVideo">
                  <source src="assets/videos/Home-video.mp4" type="video/mp4" />
                </video>
              </div>

              <div class="row" style={{ color: "black" }}>
                <div class="col banner-head-col">
                  <div class="banner-head-wrapper my-2 pt-5">
                    <h2 class="banner-head-text">
                      <span>BREAKTHROUGH</span>
                      <br />
                      Real Estate & Property Management
                    </h2>
                  </div>
                  <div class="banner-desc-wrapper">
                    <p class="banner-desc-text">Your Neighborhood Experts</p>
                  </div>
                  <div className="text-center call-us-text mt-3">
                    <h2>
                      CALL US NOW{" "}
                      <span>
                        <a href="tel:6234714451">623-471-4451</a>
                      </span>{" "}
                    </h2>
                  </div>

                  <div className="mt-4 pt-5">
                    <div className="call-us-button-box text-center p-3 mb-2">
                      <a
                        href="#schedule-appointment"
                        class="btn btn-lg btn-success"
                      >
                        SCHEDULE YOUR APPOINTMENT TODAY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <main id="main">
        <section class="section-services section-t8">
          <div class="container">
            <div class="text-center mb-5 pb-3">
              <div class="title-box ">
                <h2 class="display-4">The 3 Pillars Of Success.</h2>
                <h4>Keep your investments performing strong</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 p-4 p-md-3">
                <div class="card-box-c foo">
                  <div class="card-header-c d-flex">
                    <div class="card-title-c align-self-center">
                      <h2>
                        <span>Maximize</span> The Rent
                      </h2>
                    </div>
                  </div>
                  <div class="card-body-c">
                    <p class="content-c">
                      Today's rents are record highs. Owners need to take
                      advantage of this, while also protecting their future with
                      the current tenants, we find the perfect balance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 p-4 p-md-3">
                <div class="card-box-c foo">
                  <div class="card-header-c d-flex">
                    <div class="card-title-c align-self-center">
                      <h2>
                        <span>Minimize</span> Vacancy
                      </h2>
                    </div>
                  </div>
                  <div class="card-body-c">
                    <p class="content-c">
                      This goes with the first pillar, while maximizing rents,
                      the property also needs to stay occupied. We provide the
                      best platform to keep your property occupied.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-4 p-sm-3">
                <div class="card-box-c foo">
                  <div class="card-header-c d-flex">
                    <div class="card-title-c align-self-center">
                      <h2>
                        <span>Minimize</span> Expenses
                      </h2>
                    </div>
                  </div>
                  <div class="card-body-c">
                    <p class="content-c">
                      With our partnerships, years in the business and handyman
                      company, we have the tools necessary to get the repairs
                      and maintenance done right, affordably, and promptly
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-agents story-section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="title-wrap d-flex justify-content-between">
                  <div class="title-box">
                    <h2 class="title-a">
                      Our <span>Story</span> and <span>Mission</span>
                    </h2>
                  </div>
                </div>
                <p class="color-text-a text-left">
                  We started PM Services nearly a decade ago with the idea,
                  "Owners should receive 100% of their rents each month" and
                  went from there. With no trust account, owners can receive
                  their rents within 3 business days. AND this service can save
                  you money each month. BREAKTHROUGH!
                  <br />
                  <br />
                  Next we thought, "Management should be paid based upon
                  performance and attaining the owners goals", NOT just taking a
                  fee each month.... So we developed our performance based value
                  model, perfect for multi unit complexes, remodels, new
                  purchases, and so much more. We only get paid when you hit
                  your milestones. BREAKTHROUGH!! Message us or call to find out
                  more!
                  <br />
                  <br />
                  Our marketing is second to none, this ensures your property
                  gets in front of the maximum amount of prospective tenants.
                  This means you will have the most applications. More
                  applications mean the best tenants. This ensures the highest
                  rents for you and your properties and the best care and
                  maintenance from all parties involved. BREAKTHROUGH!!!
                  <br />
                  <br />
                  WIN/WIN/WIN.
                  <br />
                  <br />
                </p>
              </div>
              <div className="call-us-button-box text-center p-2 mb-2">
                <a href="#schedule-appointment" class="btn btn-lg btn-success">
                  SCHEDULE YOUR APPOINTMENT TODAY
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* What will my property rent for section */}
        <section className="container" id="schedule-appointment">
          <div class="mt-3 text-center ">
            <h3 class="display-4 mb-4 title-d pt-5 ">
              What Will My Property Rent For?
            </h3>
          </div>
          <Form />
        </section>

        <section>
          <div>
            <div class="container">
              <div class="text-center mt-3">
                <h2 class="display-4 mb-5 pt-5">
                  Breakthrough Property Management Offers{" "}
                </h2>
              </div>
              <div class="row ">
                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/compete.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Competitive Rates{" "}
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/payment.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Online Payment Portals
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/onlinePortal.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Owner Portal to Manage All Properties
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/24by7.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Online Service Calls 24/7
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/staff.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Experienced Office and Service Staff
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/service.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Valleywide Service
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/inspection.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Twice a Month Inspection of Vacation Homes
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/houseRental.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Regular Inspection of Rental Properties
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 p-5 ">
                  <div class="text-center">
                    <div class="offering-img-container">
                      <img
                        src="./images/backCheck.png"
                        alt=""
                        class="single-commitment-icon"
                      />
                    </div>
                    <div class="offering-text-container">
                      <h3 class="single-commitment-head-text">
                        Extensive Credit, Background, and Reference Checks
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="call-us-button-box text-center p-1 mb-2">
                <a href="#schedule-appointment" class="btn btn-lg btn-success">
                  SCHEDULE YOUR APPOINTMENT TODAY
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="call-us-text-bottom mt-5">
          <div className="text-center container mt-3">
            <h2>
              CALL US TODAY
              <span>
                <a href="tel:6234714451"> 623-471-4451</a>
              </span>
            </h2>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
