import React from "react";

export default function HomeValue() {
  return (
    <div>
      <div class="container">
        <div
          class="d-flex justify-content-center align-items-center"
          style={{ height: "85vh" }}
        >
          <div className="w-75">
            <h3 className="pt-5">
              How much is your home worth? ...get instant property value now!
            </h3>
            <div className="text-center w-50 mx-auto">
            <input type="email" class="form-control mb-3" placeholder="e.g. 1234 Your Address ,Your City ,AZ85XX"/>
            <input type="email" class="form-control mb-3" placeholder="Unit#"/>
            <button className="btn btn-success">Submit</button>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
