import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Header/Navbar";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Blog from "./Components/Blog/Blog";

import Careers from "./Components/Careers/Careers";
import Contact from "./Components/Contact/Contact";
import Forclosures from "./Components/Forclosure/Forclosure";
import Search from "./Components/Search/Search";

import Store from "./Components/Menu/Store/Store";
import HomeValue from "./Components/Menu/HomeValue/HomeValue";
import PrivacyPolicy from "./Components/Legal/PrivacyPolicy";
import Terms from "./Components/Legal/Terms";
import TrenchLife from "./Components/Menu/TrenchLife/TrenchLife";
import Admin from "./Components/Admin/Admin";
import Thankyou from "./Components/Greeting/Thankyou";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/trench" element={<Admin />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/forclosures" element={<Forclosures />} />
        <Route exact path="/advanced-search" element={<Search />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/trench-life" element={<TrenchLife/>}/>
        <Route exact path="/store" element={<Store />} />
        <Route exact path="/home-value" element={<HomeValue />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/thankyou" element={<Thankyou />} />

      </Routes>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
