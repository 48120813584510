import React from "react";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div className="container pt-5">
      <div class="title-box-d pt-3 mt-5 ">
        <h3 class="title-d pt-5 ">Terms of Use</h3>
      </div>
      <h4>Our Contract</h4>
      <h4>
        <b>(E-SIGN)</b>
      </h4>
      <p>
        You are entering a binding contract with Real Geeks., the website
        <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>
        , and the real estate professionals or lenders who use it and their
        parents, subsidiaries and affiliates (the “Company,” “us,” “we” and
        “our”). By (1) using this website
        <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>
        (“browse wrap”), and (2) by submitting your information to “Continue
        with Facebook” or “Sign Up!” and creating a user profile and clicking to
        agree to these Terms of Use and Privacy Policy (“click wrap”), you agree
        that you are giving your express written consent to all the terms below,
        as well as our Privacy Policy, both of which contain an Arbitration
        Agreement and Class Action Waiver. You agree that your doing so
        constitutes your electronic signature, and is equivalent to a written
        signature. You may choose to receive this contract on paper by calling
        us at (808) 261 - 0616 or emailing us at-
        <b>
          <a href="mailto:rglegalinquiries@realgeeks.com">
            rglegalinquiries@realgeeks.com
          </a>
        </b>
        . You may withdraw this consent by using the opt-out procedures
        described in the “Our Communications With You” section below.
        <br />
        <br /> By accessing <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>, you are consenting
        to the information collection and use practices described in the{" "}
        <b>
          <Link to="/privacy-policy">Privacy Policy.</Link>
        </b>
      </p>
      <h4>
        <b>Our Communications With You</b>
      </h4>
      <br />
      <h5>
        <b>(TCPA Consent for United States Residents)</b>
      </h5>
      <br />
      <p>
        <b>EXPRESS WRITTEN CONSENT</b> **. BY SUBMITTING YOUR CONTACT
        INFORMATION, YOU ARE PROVIDING YOUR EXPRESS WRITTEN CONSENT TO RECEIVE
        COMMUNICATIONS FROM US AT THE EMAIL ADDRESS AND TELEPHONE NUMBERS YOU
        ENTERED INTO OUR CONTACT FORM, OR THAT YOU LATER PROVIDE TO US OR ENTER
        INTO YOUR CONTACT PAGE.**
      </p>
      <p>
        <b>E-MAILS, CALLS, AND TEXTS</b>**. THESE COMMUNICATIONS MAY INCLUDE
        TELEMARKETING MESSAGES, THROUGH THE USE OF EMAIL, LANDLINE PHONE, FAX,
        CELLULAR PHONE, AND TEXT MESSAGES (INCLUDING SMS AND MMS).**
      </p>
      <p>
        <b>AUTODIALING</b>**. WE MAY USE AN AUTOMATIC TELEPHONE DIALING SYSTEM
        (OR “AUTO-DIALER”), WHICH MAY EMPLOY AN ARTIFICIAL OR PRE-RECORDED VOICE
        OR “ROBOTEXTS.” YOUR CARRIER’S STANDARD RATES AND CHARGES MAY APPLY.**
      </p>
      <p>
        <b>NO PURCHASE NECESSARY</b>**. AGREEING TO THESE COMMUNICATIONS IS NOT
        A CONDITION OF PURCHASING ANY PROPERTY, GOODS, OR SERVICES FROM US.**
      </p>
      <p>
        <b>REVOKING CONSENT AND OPTING OUT</b>**. YOU MAY REVOKE YOUR CONSENT TO
        RECEIVE COMMUNICATIONS AT ANY TIME BY REPLYING “STOP” TO ANY OF OUR
        TEXTS, OR BY ANY OTHER REASONABLE MEANS. WE WILL MAKE A COMMERCIALLY
        REASONABLE EFFORT TO COMPLY WITH ANY COMMUNICATIONS FROM YOU OPTING OUT,
        BUT REPLY “STOP” WILL AUTOMATICALLY REVOKE YOUR CONSENT TO FURTHER TEXT
        COMMUNICATIONS, AND WE RECOMMEND THAT METHOD. WE MAY TAKE UP TO 30 DAYS
        TO STOP COMMUNICATIONS IF YOU USE A METHOD OTHER THAN THE AUTOMATIC
        REPLY “STOP.” YOU CONSENT TO RECEIVE A FINAL TEXT MESSAGE CONFIRMING
        YOUR OPT-OUT. YOU MAY REVOKE YOUR CONSENT TO RECEIVE EMAIL
        COMMUNICATIONS BY USING THE “UNSUBSCRIBE” LINK IN AN EMAIL OR ON THE
        WEBSITE OR BY ANY OTHER REASONABLE MEANS. WE WILL MAKE A COMMERCIALLY
        REASONABLE EFFORT TO COMPLY WITH ANY COMMUNICATIONS FROM YOU OPTING OUT
        OF EMAIL, BUT “UNSUBSCRIBE” WILL AUTOMATICALLY REVOKE YOUR CONSENT TO
        FURTHER EMAIL COMMUNICATIONS, AND WE RECOMMEND THAT METHOD. WE MAY TAKE
        UP TO 30 DAYS TO STOP EMAIL COMMUNICATIONS IF YOU USE A METHOD OTHER
        THAN “UNSUBSCRIBE”. THE “UNSUBSCRIBE” LINK WILL ALSO PERMIT YOU TO STOP
        TEXT COMMUNICATIONS.**
      </p>
      <p>
        <b>COMMUNICATION FREQUENCY</b>**. HOW OFTEN WE SEND YOU COMMUNICATIONS
        WILL VARY, BECAUSE THE INDIVIDUAL REALTOR® WHO COMMUNICATES WITH YOU
        WILL DETERMINE IT.**
      </p>
      <b>
        Your consent here also serves as your express written consent to
        electronic communications from us in the past.
      </b>
      <p>You represent and warrant that:</p>
      <ul>
        <li>You are at least 18 years old</li>
        <li>
          {" "}
          You live in the United States (or Canada, in which case the Canadian
          consents below apply)
        </li>
        <li>
          You have not registered on a national or statewide Do Not Call list
        </li>
        <li>
          You are the account holder for the email addresses and phone numbers
          you provided, or you have authorization from the account holder to
          give this consent*{" "}
        </li>
        <li>
          The email addresses and phone numbers you provided are accurate, and
          you will let us know if you release them to another person or
          individual
        </li>
      </ul>{" "}
      <p>
        Our mobile service is available only in certain states. Certain mobile
        features may be incompatible with your carrier or mobile device. Contact
        your carrier with questions regarding these issues.
      </p>
      <h4>
        <b>ADDITIONAL COMMUNICATIONS PROVISIONS </b>
      </h4>
      <h5>
        <b>(For Residents of Canada)</b>
      </h5>
      <p>
        In addition to the consent provided above in the Our Communications With
        You (United States) section, Canadian residents agree to the following
        provisions related to compliance with Canada’s Anti-Spam Legislation
        (“CASL”), Canada’s Personal Information Protection and Electronic
        Documents Act (“PIPEDA”) and Canadian provincial law including Alberta’
        Personal Information Protection Act (“PIPA”), and Quebec’s Act
        Respecting the Protect of Personal Information in the Private Sector:
      </p>
      <ul>
        <li>
          You agree to the provisions governing use and disclosure of personal
          information that are found in our Privacy Policy.
        </li>
        <li>
          Because the purpose of our communications include your interest in
          future real estate purchases, our communication with you will continue
          until you revoke your consent or opt-out, which indicates you are no
          longer considering real estate opportunities.
        </li>
        <li>
          Your personal information may also be transmitted to, used in, and
          stored in the United States
        </li>
      </ul>
      <h4>
        <b>DISPUTE RESOLUTION – ARBITRATION AGREEMENT</b>
      </h4>
      <h5>
        <b>(Mandatory Binding Arbitration and Class Action Waiver)</b>
      </h5>
      <p>
        <b>
          {" "}
          READ THIS ARBITRATION AGREEMENT CAREFULLY AS IT WILL HAVE A
          SUBSTANTIAL IMPACT ON HOW LEGAL CLAIMS YOU AND WE HAVE AGAINST EACH
          OTHER ARE RESOLVED. For example, if we elect to require you to
          arbitrate any claim, you will not have the right to a jury trial or
          the right to participate in a class action in court or in arbitration.
          <br />
          <br /> YOU HAVE THE RIGHT TO REJECT THIS ARBITRATION AGREEMENT AS
          DESCRIBED BELOW.If you do not reject this Arbitration Agreement and a
          Claim is arbitrated, neither you nor we will have the right to: (1)
          have a court or a jury decide the Claim; (2) engage in
          information-gathering (discovery) to the same extent as in court; (3)
          participate in a class action, private attorney general or other
          representative action in court or in arbitration; or (4) join or
          consolidate a Claim with claims of any other person. The right to
          appeal is more limited in arbitration than in court and other rights
          in court may be unavailable or limited in arbitration.
        </b>
      </p>
      <p>
        This Arbitration Agreement describes when and how a Claim (as defined
        below) arising under or related to the Terms of Use and Privacy Policy
        between you and us may be arbitrated. Arbitration is a method of
        resolving disputes in front of one or more neutral persons, instead of
        having a trial in court in front of a judge and/or jury. If a claim is
        arbitrated, each party waives its, his or her respective rights to a
        trial before a jury in connection with the Claim. It can be a quicker
        and simpler way to resolve disputes. As solely used in this Arbitration
        Agreement, the terms “we,” “us” and “our” mean “us” as defined above,
        our parent companies, wholly or majority owned subsidiaries, affiliates,
        commonly-owned companies, management companies, successors, assigns and
        any of their employees, officers and directors. For purposes of this
        Arbitration Agreement, these terms also mean any third party providing
        any goods or services in connection with the Terms of Use and Privacy
        Policy, if such third party is named as a party by you in any lawsuit
        between you and us.
      </p>
      <p>
        <b>a. Your Right to Reject Arbitration:</b> You may reject this
        Agreement by mailing a rejection notice to 6201 Hillcrest Ave Ste. 200,
        Dallas TX 75205. Terms of Use and Privacy Policy Arbitration Rejection,
        within 30 days after you agree to these terms of use. Any rejection
        notice must include your name, address and telephone number; the date
        you agreed to the Terms of Use that your rejection notice applies to;
        and your signature. Your rejection notice will apply only to this
        Agreement in the Terms of Use and Privacy Policy, but will not affect
        any term of any other contract between you and us (including without
        limitation any prior or subsequent agreement), nor will it change your
        obligation to arbitrate claims or matters covered by any prior or
        subsequent agreement to arbitrate, including each agreement to arbitrate
        that arises pursuant to the Terms of Use on the Company’s website which
        is not the subject of a valid rejection notice.
      </p>
      <p>
        <b>b. What Claims Are Covered:</b>
        “Claim” means any claim, dispute or controversy between you and us,
        whether preexisting, present or future, that in any way arises from or
        relates to the Terms of Use or Privacy Policy, your use of this website,
        your Account, any transaction in your Account, the events leading up to
        the Terms of Use or Privacy Policy (for example, any disclosures,
        advertisements, promotions or oral or written statements, warranties or
        representations made by us), communications between you and us and the
        manner of communicating, any product or service provided by us or third
        parties in connection with the Terms of Use or Privacy Policy, the
        collection of amounts due and the manner of collection, enforcement of
        any and all of the obligations a party hereto may have to another party,
        compliance with applicable laws and/or regulations (including but not
        limited to the Telephone Consumer Protection Act), or the relationships
        resulting from any of the foregoing. “Claim” has the broadest possible
        meaning, and includes initial claims, counterclaims, cross-claims and
        third-party claims and federal, state, local and administrative claims
        and claims which arose before the effective date of this Arbitration
        Agreement. It includes disputes based upon contract, tort, consumer
        rights, fraud and other intentional torts, constitution, statute,
        regulation, ordinance, common law and equity and claims for money
        damages and injunctive or declaratory relief.
        <br />
        <br /> However, “Claim” does not include: (i) any dispute or controversy
        about the validity, enforceability, coverage or scope of this
        Arbitration Agreement or any part thereof (including, without
        limitation, the Class Action Waiver set forth below, subparts (A) and
        (B) of the part (k) set forth below titled “Rules of Interpretation”
        and/or this sentence); all such disputes or controversies are for a
        court and not an arbitrator to decide; but disputes about the validity
        or enforceability of the Terms of Use or Privacy Policy as a whole are
        for the arbitrator and not a court to decide; (ii) seeking and obtaining
        from a court of competent jurisdiction (notwithstanding ongoing
        arbitration and without waiver of rights under this Arbitration
        Agreement) provisional or ancillary remedies including but not limited
        to injunctive relief, temporary restraining orders, property
        preservation orders, foreclosure, sequestration, eviction, attachment,
        replevin, garnishment, and/or the appointment of a receiver; (iii) the
        exercising of any self-help or non-judicial remedies by you or us; (iv)
        any individual action in court by one party that is limited to
        preventing the other party from using a self-help remedy and that does
        not involve a request for damages or monetary relief of any kind; or (v)
        any individual action brought by you against us in small claims court or
        your state’s equivalent court, if any. But if that action is
        transferred, removed or appealed to a different court, we then have the
        right to choose arbitration. Moreover, this Arbitration Agreement will
        not apply to any Claims that are the subject of a class action filed in
        court that is pending as of the effective date of this Arbitration
        Agreement in which you are alleged to be a member of the putative or
        certified class.
      </p>
      <p>
        <b> c. Electing Arbitration; Starting an Arbitration Proceeding:</b>
        Either you or we may elect to arbitrate a Claim by giving the other
        party written notice of the intent to arbitrate the Claim or by filing a
        motion to compel arbitration of the Claim. This notice may be given
        before or after a lawsuit has been filed concerning the Claim or with
        respect to other Claims brought later in the lawsuit, and it may be
        given by papers filed in the lawsuit, such as a motion to compel
        arbitration. Each of the arbitration administrators listed below has
        specific rules for starting an arbitration proceeding. Regardless of who
        elected arbitration or how arbitration was elected, the party asserting
        the Claim (i.e., the party seeking money damages or other relief from a
        court or an arbitrator) is responsible for starting the arbitration
        proceeding. Thus, if you assert a Claim against us in court, and we
        elect to arbitrate that Claim by filing a motion to compel arbitration
        which is granted by the court, you will be responsible for starting the
        arbitration proceeding. Similarly, if we assert a Claim against you in
        court, you assert a counterclaim against us, and we elect to arbitrate
        that counterclaim by filing a motion to compel arbitration which is
        granted by the court, you will be responsible for starting the
        arbitration proceeding. Even if all parties have opted to litigate a
        Claim in court, you or we may elect arbitration with respect to any
        Claim made by a new party or any Claim later asserted by a party in that
        or any related or unrelated lawsuit (including a Claim initially
        asserted on an individual basis but modified to be asserted on a class,
        representative or multi-party basis). Nothing in that litigation shall
        constitute a waiver of any rights under this Arbitration Agreement.
      </p>
      <p>
        <b> d. Choosing the Administrator:</b>The party starting the arbitration
        proceeding must choose one of the following arbitration organizations as
        the Administrator: the American Arbitration Association (the “AAA”), 120
        Broadway, Floor 21, New York, N.Y 10271, www.adr.org., or JAMS, 1920
        Main St Ste 300, Irvine, CA 92614, www.jamsadr.com. You may contact
        these organizations directly if you have any questions about the way
        they conduct arbitrations or want to obtain a copy of their rules and
        forms (which are also available on their websites). A single arbitrator
        shall be appointed. If for any reason the Administrator selected is
        unable or unwilling to serve or continue to serve as Administrator, the
        other company will serve as Administrator. If neither the AAA nor JAMS
        is able or willing to serve as Administrator, we and you will mutually
        agree upon an Administrator or arbitrator or a court with jurisdiction
        will appoint the Administrator or arbitrator (or arbitrators, in the
        case of a three-arbitrator panel provided for in Section “j”, below). No
        company may serve as Administrator, without the consent of all parties,
        if it adopts or has in place any formal or informal policy that is
        inconsistent with and purports to override the terms of the Class Action
        Waiver in section (e) of this Arbitration Agreement. In all cases, the
        arbitrator(s) must be a lawyer with more than 10 years of experience or
        a retired judge. Arbitration of a Claim must comply with this
        Arbitration Agreement and, to the extent not inconsistent or in conflict
        with this Arbitration Agreement, the applicable rules of the arbitration
        Administrator.
      </p>
      <p>
        <b>e. Class Action Waiver:</b>Notwithstanding any other provision of the
        Terms of Use or Privacy Policy, if either you or we elect to arbitrate a
        Claim, neither you nor we will have the right: (a) to participate in a
        class action, private attorney general action or other representative
        action in court or in arbitration, either as a class representative or
        class member; or (b) to join or consolidate Claims with claims of any
        other persons. No arbitrator shall have authority to conduct any
        arbitration in violation of this provision or to issue any relief that
        applies to any person or entity other than you and/or us individually.
        (Provided, however, that the Class Action Waiver does not apply to any
        lawsuit or administrative proceeding filed against us by a state or
        federal government agency even when such agency is seeking relief on
        behalf of a class of borrowers including you. This means that we will
        not have the right to compel arbitration of any claim brought by such an
        agency).
      </p>
      <p>
        <b>f. Location of Arbitration:</b> Any arbitration hearing that you
        attend must take place at a location reasonably convenient to your
        residence.
      </p>
      <p>
        <b>g. Cost of Arbitration:</b>
        Each Administrator charges fees to administer an arbitration proceeding
        and the arbitrator also charges fees. This includes fees not charged by
        a court. At your written request, we will pay all filing, hearing and/or
        other fees charged by the Administrator and arbitrator to you for
        Claim(s) asserted by you in an individual arbitration after you have
        paid an amount equivalent to the fee, if any, for filing such Claim(s)
        in state or federal court (whichever is less) in the judicial district
        in which you reside. (If you have already paid a filing fee for
        asserting the Claim(s) in court, you will not be required to pay that
        amount again). In addition, the administrator may have a procedure
        whereby you can seek a waiver of fees charged to you by the
        Administrator and arbitrator. We will always pay any fees or expenses
        that we are required to pay by law or the Administrator’s rules or that
        we are required to pay for this Arbitration Agreement to be enforced.
      </p>
      <p>
        <b>h. Governing Law:</b>The Terms of Use or Privacy Policy evidences a
        transaction involving interstate commerce and, therefore, this
        Arbitration Agreement is governed by the Federal Arbitration Act, 9
        U.S.C. §§ 1 et seq. (the “FAA”), and not by any state arbitration law.
        The arbitrator will not be bound by judicial rules of procedure and
        evidence that would apply in a court, or by state or local laws that
        relate to arbitration proceedings. The arbitrator will apply the same
        statutes of limitation and privileges that a court would apply if the
        matter were pending in court. The arbitrator will have the authority to
        hear and rule on appropriate dispositive motions for judgment on the
        pleadings, for failure to state a claim, or for full or partial summary
        judgment. In determining liability or awarding damages or other relief,
        the arbitrator will follow the applicable substantive law, consistent
        with the FAA that would apply if the matter had been brought in court.
        The arbitrator may award any damages or other relief or remedies that
        would apply under applicable law to an individual action brought in
        court, including, without limitation, punitive damages (which shall be
        governed by the Constitutional standards employed by the courts) and
        injunctive, equitable and declaratory relief (but only in favor of the
        individual party seeking relief and only to the extent necessary to
        provide relief warranted by that party’s individual claim). The
        arbitrator will have the authority to award fees and costs of attorneys,
        witnesses and experts to the extent permitted by the Terms of Use or
        Privacy Policy, the Administrator’s rules or applicable law. However,
        with respect to Claim(s) asserted by you in an individual arbitration,
        we will pay your reasonable attorney, witness and expert fees and costs
        if and to the extent you prevail, if applicable law requires us to or if
        we must bear such fees and costs in order for this Arbitration Agreement
        to be enforced. We will not ask you to pay or reimburse us for any fees
        we pay the Administrator or the arbitrator or for our attorneys’ fees
        and costs unless (1) the arbitrator finds that you have acted in bad
        faith (as measured by the standards set forth in Federal Rule of Civil
        Procedure 11(b)), and (2) this power does not make this Arbitration
        Agreement invalid. At the timely request of either party, the arbitrator
        must provide a brief written explanation of the basis for the award.
      </p>
      <p>
        <b>i. Right to Discovery: </b>In addition to the parties’ rights to
        obtain discovery pursuant to the arbitration rules of the Administrator,
        either party may submit a written request to the arbitrator to expand
        the scope of discovery normally allowable under the arbitration rules of
        the Administrator. The arbitrator shall have discretion to grant or deny
        that request.
      </p>
      <p>
        <b>j. Arbitration Result and Right of Appeal:</b>
        Judgment upon the award given by the arbitrator may be entered in any
        court having jurisdiction. The arbitrator’s decision is final and
        binding, except for any right of appeal provided by the FAA. The
        arbitrator’s authority shall be limited to deciding the case submitted
        by the parties to the arbitration. Therefore, no decision by any
        arbitrator shall serve as precedent in other arbitrations except in a
        dispute between the same parties, in which case it could be used to
        preclude the same claim from being re-arbitrated. If the amount of the
        Claim exceeds $25,000, any party can, within 30 days after the entry of
        the award by the arbitrator, appeal the award to a three-arbitrator
        panel administered by the Administrator. (If an appeal is not filed
        within that time period, the arbitration award shall become final and
        binding). The panel shall reconsider de novo (anew) any aspect of the
        initial award requested by the appealing party. This means that they
        shall reach their own findings of fact and conclusions of law rather
        than deferring in any manner to the original arbitrator. The decision of
        the panel shall be by majority vote. Reference in this Arbitration
        Agreement to “the arbitrator” shall mean the panel if an appeal of the
        arbitrator’s decision has been taken. The costs of such an appeal will
        be borne in accordance with subparagraph (g) above, captioned “Cost of
        Arbitration.” Any final decision of the appeal panel is subject to
        judicial review only as provided under the FAA.
      </p>
      <p>
        <b>k. Rules of Interpretation:</b>
        This Arbitration Agreement shall survive the termination, cancellation
        or suspension of the Terms of Use or Privacy Policy, any legal
        proceeding, and any bankruptcy by you, to the extent consistent with
        applicable bankruptcy law. In the event of a conflict or inconsistency
        between this Arbitration Agreement, on the one hand, and the applicable
        arbitration rules or the other provisions of the Terms of Use or Privacy
        Policy, on the other hand, this Arbitration Agreement shall govern. If
        any portion of this Arbitration Agreement is deemed invalid or
        unenforceable, it shall not invalidate the Terms of Use or Privacy
        Policy or the remaining portions of this Arbitration Agreement, except
        that: (A) The parties acknowledge that the Class Action Waiver is
        material and essential to the arbitration of any disputes between them
        and is non-severable from this Arbitration Agreement. If the Class
        Action Waiver is limited, voided or found unenforceable, then this
        Arbitration Agreement (except for this sentence) shall be null and void
        with respect to such proceeding, subject to the right to appeal the
        limitation or invalidation of the Class Action Waiver. The parties
        acknowledge and agree that under no circumstances will a class action be
        arbitrated; and <br />
        <br /> (B) If a Claim is brought seeking public injunctive relief and a
        court determines that the restrictions in the Class Action Waiver or
        elsewhere in this Arbitration Agreement prohibiting the arbitrator from
        awarding relief on behalf of third parties are unenforceable with
        respect to such Claim (and that determination becomes final after all
        appeals have been exhausted), the Claim for public injunctive relief
        will be determined in court and any individual Claims seeking monetary
        relief will be arbitrated. In such a case the parties will request that
        the court stay the Claim for public injunctive relief until the
        arbitration award pertaining to individual relief has been entered in
        court. In no event will a Claim for public injunctive relief be
        arbitrated.
      </p>
      <p>
        <b> l. Notice of Claim; Right to Resolve; Special Payment:</b>
        Prior to initiating, joining or participating in any judicial or
        arbitration proceeding regarding any Claim, the Claimant (the party who
        asserts or seeks to assert a Claim in a lawsuit or arbitration
        proceeding) shall give the other party written notice of the Claim (a
        “Claim Notice”) and a reasonable opportunity, not less than 30 days, to
        resolve the Claim. Any Claim Notice you send must include your name,
        address, telephone number and loan or account number. Any Claim Notice
        must explain the nature of the Claim and the relief that is demanded.
        You may only submit a Claim Notice on your own behalf and not on behalf
        of any other party. The Claimant must reasonably cooperate in providing
        any information about the Claim that the other party reasonably
        requests. If: (i) you submit a Claim Notice in accordance with this
        paragraph on your own behalf (and not on behalf of any other party);
        (ii) we refuse to provide the relief you request before an arbitrator is
        appointed; and (iii) an arbitrator subsequently determines that you were
        entitled to such relief (or greater relief), the arbitrator shall award
        you at least $7,500 (not including any arbitration fees and attorneys’
        fees and costs to which you may be entitled under this Arbitration
        Agreement or applicable law). We encourage you to address all Claims you
        have in a single Claim Notice and/or a single arbitration. Accordingly,
        this $7,500 minimum award is a single award that applies to all Claims
        you have asserted or could have asserted in the arbitration, and
        multiple awards of $7,500 are not contemplated by this Section.
      </p>
      <h4>
        <b>Governing Law</b>
      </h4>
      <p>
        We agree that the laws of the State of Texas, without regard to
        principles of conflict of laws, will govern the Privacy Policy and/or
        any dispute of any sort that might arise between you and the Company or
        its affiliates, except for the Arbitration Agreement, which is governed
        by the Federal Arbitration Act.
      </p>
      <h4>
        <b>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</b>
      </h4>
      <b className="my-3">
        <p>
          THIS SITE IS PROVIDED BY <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> ON AN “AS
          IS” AND “AS AVAILABLE” BASIS. <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> MAKES
          NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, AS TO THE
          OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR
          PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF
          THIS SITE IS AT YOUR SOLE RISK.
        </p>
        TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW,
        <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> DISCLAIMS ALL WARRANTIES, EXPRESS OR
        IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> DOES NOT WARRANT THAT THIS SITE, ITS
        SERVERS OR E-MAIL SENT FROM THIS SITE ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. REAL GEEKS.WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY
        KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO,
        DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES, EVEN
        IF <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> HAS BEEN SPECIFICALLY ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
      </b>
      <h4 className="my-4">
        <b>Purpose of Our Website</b>
      </h4>
      <p>
        The purpose of <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> is to allow our users to
        gain access to our real estate professional, mortgage lenders, service
        providers and data. To gain access to this network of professionals, you
        must submit certain personal information. This information will be used
        by <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> to assist you in the real estate
        transaction process. To assure the value of the services to be provided
        to you, you agree that you will provide accurate and complete
        information. Once you have been contacted by a real estate professional
        or service provider, any agreement you enter into with such third party
        will only be between you and such third party.
      </p>
      <h4 className="my-4">
        <b>Copyright & Trademarks</b>
      </h4>
      <p>
        © 2022 <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> All content included on this
        site, such as text, graphics, logos, button icons, images, audio clips,
        digital downloads, and data compilations, are the exclusive properties
        of <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>/Local MLS/Other affiliated parties
        and are all subject to copyright and other intellectual property
        protection laws. <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> and all other
        trademarks and service marks indicated on our site are the properties of
        their respective owners. These objects may not be copied for commercial
        use or distribution, nor may these objects be modified or reposted to
        other sites. Framing of any content is strictly prohibited.
      </p>
      <h4 className="my-4">
        <b>License</b>
      </h4>
      <p>
        <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> grants you a limited license to access
        and make personal use of this site and not to download or modify it, or
        any portion of it, except with the express written consent of
        <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>. This site or any portion of this site
        may not be reproduced, duplicated, copied, sold, resold, visited or
        otherwise exploited for any commercial purpose without the express
        written consent of <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>.
      </p>
      <h4 className="my-4">
        <b>Professional Real Estate Advisors</b>
      </h4>
      <p>
        Financial decisions, including decisions about real estate, are serious
        decisions that should not be taken lightly.
        <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>, its agents, employees, officers,
        subsidiaries, members or affiliates do not, via this website or
        otherwise, encourage you to make any particular decision on issues
        concerning real estate without first seeking professional advice from
        your attorney or accountant. The material contained on this site is
        offered as information only and not as financial, accounting, legal or
        other professional advice. Users of this site should contact their own
        professional advisors for such advice. <br /><br />
        You agree that you must evaluate and bear all risks associated with your
        dealings with any real estate investor or investment company or real
        estate broker or brokerage company that is linked to, included in, or
        referred to by this site. You agree and understand that your choice to
        deal with any member or affiliate of this site is done at your
        discretion and risk, and you will be solely responsible for such
        dealings. In no event shall <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> be liable
        for any direct, indirect, incidental, punitive or consequential damages
        of any kind whatsoever with respect to your dealings with investors,
        members or affiliates of this site. <br /><br />
        On occasion, information on our site, such as home prices, may be
        outdated or otherwise incorrect. We can only provide information with
        which we have been provided and accept no responsibility for any
        information that is out of date or otherwise invalid.
      </p>
      <p><b>Obligations of Home Sellers Registered With <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b></b></p>
      <p>By completing the home information form on this site, you agree that:</p>
      <ul>
        <li>
        You are of legal age and are otherwise capable of forming a legally binding contract;
        </li>
        <li>
        You are interested in buying or selling property;
        </li>
        <li>
        You do not have an exclusive contractual or other arrangement with any real estate professional; and
        </li>
        <li>You agree to be contacted by e-mail, telephone, text message or other means by</li>
       
      </ul>
      <p>
      <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> or a member or affiliate of <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> regarding content viewed on <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b>
      </p>
      <h4>
        <b>
        Links to Other Sites
        </b>
      </h4>
      <p>
      From time to time, <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> may include on its site third party service providers (such as a lender, mortgage broker loan originator, etc.). <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> may post information about, or links to, these companies as a service to its users who may be interested in such services. <b>
          <a href="https://www.breakthroughrealestateaz.com/"> www.breakthroughrealestateaz.com</a>
        </b> is not responsible for the contents of any link to or from this site. We do not endorse the policies or practices of, or opinions expressed on, other websites linked to or from this site; nor do we make any representations or warranties with respect to the accuracy of any items or claims contained in such other websites. Any links are provided for you only as a convenience, and the inclusion of any link does not imply endorsement by us of the services, the site, its contents or its sponsoring organization.
      </p>
      <h4 className="my-4">
        <b>Your consent to future changes</b>
      </h4>
      <p>
      You agree that we may change the website, these Terms of Use, and our Privacy Policy at any time. If we change these Terms of Service or some part of them, they will become effective immediately on posting of the updated or revised Privacy Policy on this web page regardless of whether or not you have actual notice of the changes. You should review our Terms of Use and Privacy Policy periodically for changes. Additionally, you agree that any use of the website following our publication of any changes to these Terms of Service or Privacy Policy will expressly reaffirm your express written consent to the Terms of Use, and acceptance of the changes. Should you wish to opt-out of such future changes, you must communicate your request to opt-out to us in writing, either by email at rglegalinquiries@realgeeks.com or at this address: 6201 Hillcrest Ave. #200, Dallas, TX 75205. The opt-out shall be effective 10 days after receipt. In the event you opt-out, our agreement will continue to be governed by the Terms of Use in effect at the time you originally submitted your information, or at the time of the last update to which you did not opt-out.
      <br />
        <br />
        Effective as of: 5/11/2015 <br />
        Last updated: 3/29/2019
     </p>
     
       

    </div>
  );
}

export default Terms;
